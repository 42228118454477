import TransitionEmit from './TransitionEmit.js';

export default function TransitionEnd(runCallbacks = true, direction) {
  const swiper = this;
  const { params } = swiper;
  swiper.animating = false;
  if (params.cssMode) return;
  swiper.setTransition(0);

  TransitionEmit({ swiper, runCallbacks, direction, step: 'End' });
}
