import TransitionEmit from './TransitionEmit.js';

export default function TransitionStart(runCallbacks = true, direction) {
  const swiper = this;
  const { params } = swiper;
  if (params.cssMode) return;
  if (params.autoHeight) {
    swiper.updateAutoHeight();
  }

  TransitionEmit({ swiper, runCallbacks, direction, step: 'Start' });
}
